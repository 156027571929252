import { useState, useEffect, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import add from '../../photos/icons/ios-compose.svg';
import trophy from '../../photos/icons/trophy.svg';
import coins from '../../photos/icons/coins-solid.svg';
import users from '../../photos/icons/users-solid.svg';
import { injected } from '../../connectors';
import IconButton from "@mui/material/IconButton";
import { faDiscord, faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ethers } from "ethers";
import "../../componentsStyling/permanentDrawer.scss";
import ScreenSize from '../Common/ScreenSize';
import ContactsContext from '../Contexts/ContactsContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SnackbarNotification from './Snackbar';
import { Add } from '@mui/icons-material';
import ContactsSetter from '../ContactsSetter';
import useAnalyticsEventTracker from '../Common/GaEventTracker';

declare global {
    interface Window {
        ethereum: any;
    }
}

export function PermanentDrawer(props: any): any {
    const context = useWeb3React()
    const { connector, account } = context
    const [activatingConnector, setActivatingConnector] = useState<any>()
    const [selectedIndex, setSelectedIndex] = useState<any>(6);
    const menuItems = ['Compose', 'Deb0x', 'Stake', 'Sent', 'Mint', 'DBX Yellow pages'];
    const dimensions = ScreenSize();
    const useContacts = () => useContext(ContactsContext);
    const { contacts, setContacts } = useContacts()!;
    const [notificationState, setNotificationState] = useState({});
    const [networkName, setNetworkName] = useState<any>();
    let [show, setShow] = useState(false);
    const gaEventTracker = useAnalyticsEventTracker('Add Contact');

    useEffect(() => {
        injected.supportedChainIds?.forEach(chainId => 
            setNetworkName((ethers.providers.getNetwork(chainId).name)));
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined)
        }
    }, [activatingConnector, connector])


    function handleChange(text: any, index: any) {
        setSelectedIndex(index)
        props.onChange(text)
        if(index !== 0)
            localStorage.removeItem('input')
    }

    function deleteContact(index: any) {
        const contactsList = contacts.filter((_, i) => i !== index);
        setContacts(contactsList);
    }

    useEffect(() => {
        setTimeout(() => {setNotificationState({})}, 2000)
    }, [notificationState])

    return (
        <>
            <SnackbarNotification state={notificationState} 
                setNotificationState={setNotificationState} />
            <Box className="side-menu-box" sx={{ display: 'flex' }}>
                <Drawer variant="permanent"
                    anchor={dimensions.width > 768 ? 'left' : 'bottom'}
                    className="side-menu">
                    <div className="image-container" 
                        onClick={() => handleChange("Home", 4)}>
                        <div className="img"></div>
                    </div>
                    { account  && 
                        <List className="menu-list">
                            {menuItems.map((text, index) => (
                                <>
                                    <ListItem button key={text} 
                                        selected={selectedIndex === index} 
                                        onClick={() => handleChange(text, index)}
                                        className={`list-item ${index === 0 ? "send-item" : ""}` }>
                                        
                                        <ListItemIcon className="icon" >
                                            {index === 0 && <img src={add} />}
                                            {index === 1 && <InboxIcon />} 
                                            {index === 2 && <img src={trophy} />}
                                            {index === 3 && <SendIcon className="sent-icon-color"/>}
                                            {index === 4 && <img src={coins} />}
                                            {index === 5 && <img src={users} />}
                                        </ListItemIcon>
                                        <ListItemText className="text" primary={text} />
                                    </ListItem>
                                </>
                            ))}
                        </List>
                    }
                    
                    <div className="side-menu--bottom">
                        { account && 
                            <div className="contacts">
                                <List>
                                    {contacts.map((contact: any, index: any) => (
                                        <ListItem button key={contact.name}>
                                            <ListItemText className="text" primary={contact.name}/>
                                            <div className="col-4 buttons">
                                                <IconButton size="small"
                                                    onClick={() => {
                                                            navigator.clipboard.writeText(contact.address);
                                                            setNotificationState({
                                                                message: "Address added to clipboard.",
                                                                open: true,
                                                                severity: "success"
                                                            })
                                                        }}>
                                                    <ContentCopyIcon className="copy-icon" fontSize="small"/>
                                                </IconButton>
                                                <IconButton size="small"
                                                    onClick={() => {
                                                        // setNotificationState({})
                                                        localStorage.setItem("input", JSON.stringify(contact.address));
                                                        if (document.querySelector(".editor")) {
                                                            (document.querySelector(".editor") as HTMLElement).click();
                                                        } else {
                                                            handleChange("Compose", 0)
                                                        }
                                                    }}>
                                                    <SendIcon className="send-icon" fontSize="small"/>
                                                </IconButton>
                                                <IconButton size="small"
                                                    onClick={() => {
                                                        deleteContact(index)
                                                    }}>
                                                    <DeleteIcon className="delete-icon" fontSize="small"/>
                                                </IconButton>
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>
                                <>
                                    <IconButton className='add-new-all' onClick={() => {setShow(true); gaEventTracker('New contact menu');}}>
                                        <Add className="add-button"/>
                                        <p className='add-new mb-0'>Add new</p>
                                    </IconButton>
                                    {show ? 
                                        <ContactsSetter show={show} onClickOutside={() => setShow(false)}/> : 
                                        <></>
                                    }    
                                </>
                            </div>
                        }
                        <div className="content">
                            <div className="social-media">
                                <a href="https://mobile.twitter.com/deb0xDAO" target="_blank" className="logo-text-color">
                                    <FontAwesomeIcon icon={faTwitter} size="xl"/>
                                </a>
                                <a href="https://discord.com/invite/btejt3kUcN" target="_blank" className="logo-text-color">
                                    <FontAwesomeIcon icon={faDiscord} size="xl"/>
                                </a>
                                <a href="https://github.com/deb0x" target="_blank" className="logo-text-color">
                                    <FontAwesomeIcon icon={faGithub} size="xl"/>
                                </a>
                            </div>
                            <a href="https://www.deb0x.org" target="_blank" className='logo-text-color'>
                                www.deb0x.org
                            </a>
                        </div>
                    </div>
                </Drawer>
            </Box>
        </>
    );
}